<!-- =========================================================================================
    File Name: UserProfile.vue
    Description: user profile sidebar
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div style="height: 100%">
    <feather-icon
      v-if="showRequestForm"
      class="cursor-pointer"
      icon="ArrowLeftIcon"
      @click.stop="
        showRequestForm = false;
        requestId = 'all';
      "
    />
    <div v-if="!showRequestForm">
      <vs-button
        color="success"
        icon-pack="feather"
        icon="icon-cpu"
        @click="createNew()"
        >Nova Requisição</vs-button
      >
      <vs-list>
        <vs-list-header title="Requests List"></vs-list-header>
        <vs-list-item
          v-for="(item, index) in requestList"
          :key="index"
          :title="item.name"
          :subtitle="item.request.url"
        >
          <div class="flex">
            <vs-button
              color="primary"
              icon-pack="feather"
              icon="icon-edit"
              @click="
                reset();
                showRequestForm = true;
                requestId = item._id;
                getRequest();
              "
            >
            </vs-button>
            <vs-button
              class="ml-3"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
              @click="deleteRequest(item._id)"
            ></vs-button>
          </div>
        </vs-list-item>
      </vs-list>
    </div>
    <form autocomplete="off" id="whForm" v-if="showRequestForm">
      <div class="mb-4">
        <h4 class="">Requisição</h4>
        <vs-input
          v-model="requestName"
          class="w-1/2"
          required
          type="text"
          label="Nome da Requisição"
        />
      </div>
      <div class="mb-4 flex">
        <v-select
          class="w-32 flex-none"
          data-method
          id="data-method"
          :options="['GET', 'POST', 'PUT', 'PATCH', 'DELETE']"
          v-model="request.method"
        >
        </v-select>

        <vs-input
          v-model="url"
          class="flex-initial w-full ml-3 mr-3"
          required
          type="url"
          placeholder="htttps://www.example.com"
        />
      </div>

      <vs-tabs v-model="tabIndex">
        <vs-tab
          v-for="(item, index) in tabs"
          :key="index"
          :label="getTabTitle(index)"
          :disabled="request.method === 'GET' && getTabTitle(index) === 'Body'"
        >
          <div>
            <ul class="flex" v-if="item === 'data'">
              <li>
                <vs-radio
                  v-model="requestBodyDataType"
                  vs-name="radios1"
                  vs-value="formdata"
                  >Form-Data</vs-radio
                >
              </li>
              <li class="ml-4">
                <vs-radio
                  v-model="requestBodyDataType"
                  vs-name="radios1"
                  vs-value="json"
                  >Json</vs-radio
                >
              </li>
            </ul>
            <vs-list :key="componentKey">
              <vs-list-header
                title="Basic Auth"
                v-if="item === 'headers'"
              ></vs-list-header>
              <vs-list-item v-if="item === 'headers'">
                <template slot="avatar">
                  <vs-input
                    name="auth_username"
                    type="text"
                    autocomplete="off"
                    class="flex-1 mr-1"
                    placeholder="User"
                    v-model="request.auth.username"
                  />
                  <vs-input
                    name="auth_pass"
                    autocomplete="off"
                    type="text"
                    class="flex-1"
                    placeholder="Password"
                    v-model="request.auth.password"
                  />
                </template>
              </vs-list-item>
              <vs-list-header title="Structure"></vs-list-header>
              <vs-list-item
                v-for="(item2, index2) in request[item]"
                :key="index2"
              >
                <template slot="avatar">
                  <div class="w-full flex">
                    <vs-input :value="index2" disabled class="flex-none mr-1" />
                    <vs-input :value="item2" disabled class="flex-1 mr-1" />
                    <vs-button
                      color="danger"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click="unsetParam(item, index2)"
                    >
                    </vs-button>
                  </div>
                </template>
              </vs-list-item>
            </vs-list>
            <vs-button
              color="primary"
              icon-pack="feather"
              size="small"
              class="mt-2 btn btn-outline-success mr-3"
              @click="addParam(item, 'custom')"
            >
              Add Parâmetro
            </vs-button>
            <vs-button
              color="primary"
              icon-pack="feather"
              size="small"
              class="mt-2 btn btn-outline-success mr-3"
              @click="addParam(item, 'otherRequest')"
            >
              Add Request Response
            </vs-button>
          </div>
        </vs-tab>
      </vs-tabs>
      <div ref="dataKeyValueTemplate" class="hidden">
        <div class="flex my-2">
          <vs-input
            type="text"
            data-key
            id="data-key"
            class="flex-none mr-1"
            placeholder="Key"
            ref="paramKey"
            v-model="paramKey"
          />
          <v-select
            ref="paramOtherRequest"
            type="text"
            class="flex-1 mr-1"
            placeholder="Request Response"
            :options="otherRequestOptions"
            v-model="paramOtherRequest"
          ></v-select>
          <vs-input
            id="data-value"
            type="text"
            data-value
            class="flex-1 mr-1"
            placeholder="Value"
            ref="paramCustom"
            v-model="paramCustom"
          />
          <vs-button
            color="success"
            icon-pack="feather"
            icon="icon-plus"
            :disabled="!validatedParam"
            @click="setParam()"
          >
          </vs-button>
        </div>
      </div>
      <div class="mt-4" v-if="responseTest">
        <vs-textarea
          label="Retorno"
          v-model="responseTest"
          disabled
          class="w-full mt-2"
        />
      </div>
      <div class="mt-4">
        <h4>Retorno</h4>
        <h6 class="mt-3">
          JSONPath
          <feather-icon
            icon="InfoIcon"
            svgClasses="w-4 h-4 text-warning cursor-pointer"
            @click="showJsonPathInfo = !showJsonPathInfo"
          />
        </h6>
        <div v-if="showJsonPathInfo">
          <p class="mt-2">{{ $t("JsonPathText1") }}</p>
          <p class="mt-2 mb-2">{{ $t("JsonPathText3") }}</p>
          <vs-alert active="true">
            <ul>
              <li>$.data.name</li>
              <li>$.users[0].name</li>
            </ul>
          </vs-alert>
          <p class="mt-2 mb-2">{{ $t("JsonPathText2") }}</p>
          <vs-alert active="true">
            Veja mais sobre JSONPath e como utilizar
            <a href="https://jsonpath.com/" target="_blank"><u>nessa</u></a> e
            <a
              href="https://goessner.net/articles/JsonPath/index.html#e2"
              target="_blank"
              ><u>nessa página</u></a
            >.
          </vs-alert>
        </div>
        <p>
          Informe o tratamento da variavel de retorno para utilizar nas próximas
          cosnultas
        </p>
        <vs-input
          v-model="requestJsonPath"
          class="w-full"
          required
          type="text"
          label="Variável JSONPath"
        />
      </div>
      <div class="mt-4">
        <h4 class="mb-2">Ação</h4>
        <vs-checkbox v-model="requestSetResponseAsEntity" class="mb-3">
          Inserir resposta como valor na entidade da pergunta atual
        </vs-checkbox>
        <vs-checkbox v-model="requestActionOnReturnToFlow" class="mb-3">
          Habilitar inserção da variável de retorno como mensagem no fluxo
        </vs-checkbox>
        <vs-checkbox v-model="requestSetResponseAsOperator" class="mb-3">
          Utilizar resposta para transferir conversa para atendente
        </vs-checkbox>
        <vs-checkbox v-model="forceQuestion">
          Habilitar forçar pergunta
        </vs-checkbox>
        <div
          v-for="(item, index) in forceQuestionID"
          :key="`forceQuestion_${index}`"
          class="flex my-2 mt-3"
        >
          <vs-input
            type="text"
            class="flex-none mr-1"
            v-model="forceQuestionJPathValue[index]"
            disabled
          />
          <vs-input
            type="text"
            id="data-key"
            class="flex-1 mr-1"
            v-model="item.label"
            disabled
          />
          <vs-button
            class="ml-3"
            color="danger"
            icon-pack="feather"
            icon="icon-trash"
            @click="deleteForceQuestionID(index)"
          ></vs-button>
        </div>
        <vs-button
          v-if="forceQuestion"
          color="primary"
          icon-pack="feather"
          size="small"
          class="mt-2 btn btn-outline-success mr-3"
          @click="forceQuestionADD = true"
        >
          Add Force Question
        </vs-button>
        <div v-if="forceQuestionADD && forceQuestion" class="flex my-2 mt-3">
          <vs-input
            type="text"
            data-key
            id="data-key"
            class="flex-none mr-1"
            placeholder="if JSON Path equals"
            v-model="forceQuestionJPathValueADD"
          />
          <v-select
            type="text"
            class="flex-1 mr-1"
            placeholder="Question to force"
            :options="labelsWithIndexes"
            v-model="forceQuestionIDADD"
          ></v-select>
          <vs-button
            color="success"
            icon-pack="feather"
            icon="icon-plus"
            :disabled="!forceQuestionJPathValueADD || !forceQuestionIDADD.value"
            @click="addForceQuestionID"
          >
          </vs-button>
        </div>
        <div v-if="requestActionOnReturnToFlow">
          <p class="mt-4">Mensagem de retorno</p>
          <vs-textarea
            label="Mensagem"
            v-model="requestTextToFlow"
            class="w-full mt-2"
            placeholder="Ex.: Para pagar o boleto clique no {link}"
          />
          <small>
            Para usar a variável de retorno insira {JsonPath} no texto<br />
            Para criar botões com o resultado utilize essa formatação: [button]
            {data_vencimento} - {link} [/button]<br />
            Para listas utilize a formatação do exemplo: Segue os links de
            boletos em aberto: [list] {data_vencimento} - {link} [/list]<br />
          </small>
          <h5 class="mt-2">Mensagens de erro</h5>
          <p class="mt-2">
            Informe o JsonPath dos erros possíveis, na ordem crescente de
            prioridade, separados por |
          </p>
          <vs-input
            v-model="errorMessagesJsonPath"
            class="w-full"
            required
            type="text"
            label="Mensagem de erro"
          />
          <p class="mt-2">
            Em caso de erro na requisição, informar uma mensagem de erro ao
            cliente
          </p>
          <vs-input
            v-model="errorMessage"
            class="w-full"
            required
            type="text"
            label="Mensagem de erro"
          />
        </div>
      </div>
      <vs-divider />
      <div class="w-full">
        <h4>Testar</h4>
        <p>
          Informe os valores das entidades necessarias para testar a requisição
        </p>
        <li class="flex flex-wrap">
          <ul v-for="(item, index) in entities" :key="index">
            {{
              item
            }}:
            <vs-input
              name="auth_username"
              type="text"
              autocomplete="off"
              class="flex-1 mr-1"
              v-model="entitiesTest[`${item}`]"
            />
          </ul>
        </li>
        <div class="mt-1">
          <vs-input
            name="timeout"
            icon-after="true"
            icon="access_time_filled"
            type="text"
            v-model="timeout"
            label="Timeout (ms)"
          />
        </div>
        <vs-button
          class="mt-4"
          :disabled="!validatedRequest"
          @click="testRequest()"
        >
          {{ $t("Test") }}</vs-button
        >
      </div>

      <vs-divider />

      <div v-if="testResponseStatus" class="flex w-full">
        <div class="w-1/2">
          Response code: {{ testResponseStatus }}

          <vs-textarea
            v-if="testResponseDataRaw"
            label="Raw response data"
            v-model="testResponseDataRaw"
            class="w-full mt-2"
          />
          <vs-textarea
            v-if="testResponseJPath"
            label="Parsed response data"
            v-model="testResponseJPath"
            class="w-full mt-2"
          />
          <vs-textarea
            v-if="testResponseData"
            label="Parsed response data"
            v-model="testResponseData"
            class="w-full mt-2"
          />
        </div>
        <vs-card class="w-1/4 h-60 ml-10 flex-none" v-if="testResponseData">
          <div slot="header">
            <h5>Chat Response Example</h5>
          </div>
          <div>
            <flow-webhook-whatsapp-demo
              :initialMessages="exampleMessages"
              :key="`example-${exampleKey}`"
            />
          </div>
        </vs-card>
      </div>
      <vs-divider />

      <vs-button
        color="success"
        class="mt-4"
        :disabled="!validatedRequest"
        @click="saveRequest()"
      >
        {{ $t("Save") }}</vs-button
      >
    </form>
  </div>
</template>

<script>
import vSelect from "vue-select";
import FlowWebhookWhatsappDemo from "./FlowWebhookWhatsappDemo.vue";

export default {
  name: "FlowWebhook",
  components: {
    "v-select": vSelect,
    "flow-webhook-whatsapp-demo": FlowWebhookWhatsappDemo,
  },
  props: {
    questionId: {
      required: true,
    },
    intentions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      exampleKey: 0,
      initialMessagesDefault: [
        {
          text: "Lorem ipsum dolor sit amet",
          type: "incoming",
          time: new Date(
            new Date().getTime() - 5 * 60 * 1000
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        },
        {
          text: "Dolor sit amet",
          type: "incoming",
          time: new Date(
            new Date().getTime() - 2 * 60 * 1000
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        },
      ],
      timeout: 20000,
      entities: [],
      showRequestForm: false,
      requestList: [],
      showJsonPathInfo: false,
      tabs: ["headers", "params", "data"],
      componentKey: 0,
      tabIndex: 0,
      form: null,
      responseTest: "",
      entitiesTest: {},
      testResponseStatus: null,
      testResponseParsedResponse: {},
      testResponseData: "",
      testResponseJPath: "",
      testResponseDataRaw: "",
      requestMapFunctionOperatorsOptions: [
        "equal",
        "includes",
        "diff",
        "exists",
        "dont_exists",
        "gt",
        "gt_equal",
        "lt",
        "lt_equal",
        "index",
      ],

      destination: null,
      paramType: null,
      paramKey: "",
      paramEntity: "",
      paramCustom: "",
      paramOtherRequest: "",

      requestId: "all",
      requestName: "",
      requestJsonPath: "",
      requestActionOnReturnToFlow: false,
      requestStatus: 1,
      requestTextToFlow: "",
      requestBodyDataType: "json",
      errorMessage: "",
      errorMessagesJsonPath: "",
      requestMapFunction: [],
      requestMapFunction2: [],
      requestSetResponseAsEntity: false,
      requestSetResponseAsOperator: false,
      requestSetResponseToForceQuestion: false,
      forceQuestion: false,
      forceQuestionID: [],
      forceQuestionJPathValue: [],
      forceQuestionADD: false,
      forceQuestionIDADD: { value: "", label: "" },
      forceQuestionJPathValueADD: "",
      url: "",
      request: {
        url: "",
        method: "GET",
        headers: {},
        params: {},
        data: {},
        auth: {
          username: null,
          password: null,
        },
        responseType: "json", // default
      },
    };
  },
  mounted() {
    this.getRequest();
  },
  watch: {
    url(val, oldVal) {
      this.request.url = val;

      // Extract matches from the current and previous URLs
      const currentMatches = val.match(/@[\w_-]+/g) || [];
      const oldMatches = oldVal.match(/@[\w_-]+/g) || [];

      // Add new matches that are not already in `entities`
      let entities = [
        ...this.entities,
        ...currentMatches.filter((match) => !this.entities.includes(match)),
      ];

      // Remove old matches that are no longer in the current URL
      entities = entities.filter(
        (entity) =>
          !oldMatches.includes(entity) || currentMatches.includes(entity)
      );
      this.extractEntities(entities);
    },
    requestId() {
      this.hideParams();
      this.resetParams();
      this.componentKey += 1;
    },
    showRequestForm(val) {
      const thisIns = this;
      setTimeout(() => {
        if (val) {
          thisIns.hideParams();
          thisIns.resetParams();
          thisIns.componentKey += 1;
        }
      }, 800);
    },
    tabIndex() {
      this.hideParams();
      this.resetParams();
      this.componentKey += 1;
    },
  },
  computed: {
    formFields2: function () {
      return this.$store.state.chatbotManagement.current_chatbot.formFields2;
    },
    labelsWithIndexes: function () {
      return this.formFields2.map((node, index) => ({
        label: node.label || `Mensagem sem título. Mensagem número ${index}`,
        value: node.id,
      }));
    },
    otherRequestOptions: function () {
      return this.requestList.map((el) => el["name"]);
    },
    validatedRequest: function () {
      return (
        this.request.url !== "" &&
        this.request.method !== "" &&
        this.requestName !== ""
      );
    },
    validatedParam: function () {
      return (
        this.paramKey !== "" &&
        (this.paramEntity !== "" ||
          this.paramCustom !== "" ||
          this.paramOtherRequest !== "")
      );
    },
  },
  methods: {
    extractEntities(stageEntities = null) {
      const entityKeys = ["params", "data", "auth", "headers"];
      let entities = stageEntities || [];
      entityKeys.forEach((key) => {
        if (this.request[key]) {
          entities = entities.concat(
            Object.values(this.request[key]).filter(
              (value) => value && value.startsWith("@")
            )
          );
        }
      });
      this.entities = [
        ...new Set([...entities]), // Add only unique elements
      ];
    },
    createNew() {
      this.reset();
      this.showRequestForm = true;
      this.requestId = "all";
    },
    reset() {
      this.url = "";
      this.request = {
        url: "",
        method: "GET",
        headers: {},
        params: {},
        data: {},
        auth: {
          username: null,
          password: null,
        },
        responseType: "json", // default
      };
      this.requestMapFunction = [];
      this.requestMapFunction2 = [];
      this.requestSetResponseAsEntity = false;
      this.requestSetResponseAsOperator = false;
      this.requestSetResponseToForceQuestion = false;
      this.forceQuestion = false;
      this.forceQuestionID = [];
      this.forceQuestionJPathValue = [];
      this.forceQuestionADD = false;
      this.forceQuestionIDADD = { value: "", label: "" };
      this.forceQuestionJPathValueADD = "";
      this.requestId = "all";
      this.requestName = "";
      this.requestJsonPath = "";
      this.requestActionOnReturnToFlow = false;
      this.requestStatus = 1;
      this.requestTextToFlow = "";
      this.requestBodyDataType = "json";
      this.errorMessage = "";
      this.errorMessagesJsonPath = "";
      this.showRequestForm = false;
      this.componentKey += 1;
      this.timeout = 20000;
    },
    generateExampleMessages() {
      /*const entities = Object.entries(this.entitiesTest).map(
        ([key, value]) => ({
          text: `${key}: ${value}`,
          type: "outgoing",
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        })
      );
      this.exampleMessages = entities.concat([
        {
          text: this.testResponseParsedResponse.text,
          type: "outgoing",
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        },
      ]);*/
      this.exampleMessages = [
        {
          text: this.testResponseParsedResponse.text,
          type: "outgoing",
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        },
      ];
      this.exampleKey++;
    },
    addMap() {
      this.requestMapFunction.push({ operator: "", var1: "", var2: "" });
    },
    rmMap(index) {
      this.requestMapFunction.splice(index, 1);
    },
    addMap2() {
      this.requestMapFunction2.push({ operator: "", var1: "", var2: "" });
    },
    rmMap2(index) {
      this.requestMapFunction2.splice(index, 1);
    },
    getRequest() {
      this.$vs.loading();
      this.$http
        .get(`/p/chat/chatbot-flow-apicall/${this.requestId}`, {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            questionId: this.questionId,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          const data = response.data.data;
          if (this.requestId === "all") this.requestList = data;
          else {
            const forceQuestionIDNode = (id) => {
              return this.formFields2.find((el) => el.id === id);
            };
            const forceQuestionIDsArray = data.forceQuestionID
              ? data.forceQuestionID.split("|")
              : [];
            for (let i = 0; i < forceQuestionIDsArray.length; i += 1) {
              const flowElement = forceQuestionIDNode(forceQuestionIDsArray[i]);
              if (flowElement)
                this.forceQuestionID.push({
                  value: forceQuestionIDsArray[i],
                  label: flowElement.label.length
                    ? flowElement.label
                    : `Mensagem sem título. Id: ${forceQuestionIDsArray[i]}`,
                });
            }

            this.requestName = data.name;
            this.requestJsonPath = data.jsonPath;
            this.requestActionOnReturnToFlow = data.returnToFlow;
            this.requestSetResponseAsEntity = data.setResponseAsEntity;
            this.requestSetResponseAsOperator = data.setResponseAsOperator;
            this.forceQuestionJPathValue = data.forceQuestionJPathValue
              ? data.forceQuestionJPathValue.split("|")
              : [];

            if (data.forceQuestionJPathValue) this.forceQuestion = true;

            this.request = data.request;
            this.url = data.request.url;
            this.errorMessage = data.errorMessage;
            this.errorMessagesJsonPath = data.errorMessagesJsonPath;
            this.requestBodyDataType = data.bodyDataType;
            this.requestTextToFlow = data.textToFlow;
            this.requestStatus = data.status;
            this.requestMapFunction = data.mapFunction || [];
            this.requestMapFunction2 = data.mapFunction2 || [];

            this.extractEntities();
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    saveRequest() {
      this.$vs.loading();
      this.$http
        .post(
          `/p/chat/chatbot-flow-apicall?acc=${this.$store.state.acc.current_acc.id}`,
          {
            chat: this.$store.state.chatbotManagement.current_chatbot.id,
            acc: this.$store.state.acc.current_acc.id,
            _id: this.requestId,
            name: this.requestName,
            jsonPath: this.requestJsonPath,
            returnToFlow: this.requestActionOnReturnToFlow,
            request: this.request,
            textToFlow: this.requestTextToFlow,
            errorMessage: this.errorMessage,
            errorMessagesJsonPath: this.errorMessagesJsonPath,
            bodyDataType: this.requestBodyDataType,
            questionId: this.questionId,
            status: this.requestStatus,
            setResponseAsEntity: this.requestSetResponseAsEntity === true,
            setResponseAsOperator: this.requestSetResponseAsOperator === true,
            forceQuestionID: this.forceQuestionID.length
              ? this.forceQuestionID.map((el) => el.value).join("|")
              : undefined,
            forceQuestionJPathValue: this.forceQuestionJPathValue.join("|"),
          }
        )
        .then(async () => {
          this.showRequestForm = false;
          this.requestId = "all";
          this.$vs.loading.close();
          this.getRequest();
          this.$vs.notify({
            color: "success",
            title: this.$t("Confirm"),
            text: "Request created successfuly",
            position: "top-right",
            time: 4000,
          });
        })
        .catch(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: this.$t("Error"),
            text: this.$t("UnexpectedError"),
            position: "top-right",
            time: 4000,
          });
        });
    },
    deleteRequest(id) {
      this.$vs.loading();
      this.$http
        .delete(
          `/p/chat/chatbot-flow-apicall/${id}?acc=${this.$store.state.acc.current_acc.id}`,
          {
            chat: this.$store.state.chatbotManagement.current_chatbot.id,
            acc: this.$store.state.acc.current_acc.id,
          }
        )
        .then(async () => {
          this.showRequestForm = false;
          this.requestId = "all";
          this.$vs.loading.close();
          this.getRequest();
          this.$vs.notify({
            color: "success",
            title: this.$t("Confirm"),
            text: "Request created successfuly",
            position: "top-right",
            time: 4000,
          });
        })
        .catch(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: this.$t("Error"),
            text: this.$t("UnexpectedError"),
            position: "top-right",
            time: 4000,
          });
        });
    },
    testRequest() {
      this.$vs.loading();
      this.$http
        .post(
          `/p/chat/chatbot-flow-apicalltest?acc=${this.$store.state.acc.current_acc.id}`,
          {
            chat: this.$store.state.chatbotManagement.current_chatbot.id,
            acc: this.$store.state.acc.current_acc.id,
            _id: this.requestId,
            name: this.requestName,
            jsonPath: this.requestJsonPath,
            returnToFlow: this.requestActionOnReturnToFlow,
            request: this.request,
            textToFlow: this.requestTextToFlow,
            errorMessage: this.errorMessage,
            errorMessagesJsonPath: this.errorMessagesJsonPath,
            bodyDataType: this.requestBodyDataType,
            questionId: this.questionId,
            status: this.requestStatus,
            entities: this.entitiesTest,
            setResponseAsEntity: this.requestSetResponseAsEntity === true,
            setResponseAsOperator: this.requestSetResponseAsOperator === true,
          },
          { timeout: this.timeout }
        )
        .then(async (response) => {
          if (
            response.data.data.parsedResponse &&
            response.data.data.parsedResponse.text
          )
            this.testResponseParsedResponse = response.data.data.parsedResponse;
          this.testResponseData = JSON.stringify(
            response.data.data.parsedResponse,
            null,
            "\t"
          );
          this.testResponseJPath = JSON.stringify(
            response.data.data.jpathResponse,
            null,
            "\t"
          );
          this.generateExampleMessages();
          this.testResponseDataRaw = JSON.stringify(
            response.data.data.rawResponse,
            null,
            "\t"
          );
          this.testResponseStatus = response.status;
          this.$vs.loading.close();
          this.$vs.notify({
            color: "success",
            title: this.$t("Confirm"),
            text: "Test response 200 code",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.testResponseData = "";
          this.testResponseJPath = "";
          this.testResponseTimeout = 20000;
          this.testResponseDataRaw = JSON.stringify(
            error.response.data,
            null,
            "\t"
          );
          this.testResponseStatus = error.response.status;
          this.$vs.notify({
            color: "danger",
            title: this.$t("Error"),
            text: "Request error",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getTabTitle(tab) {
      if (tab === 0) return "Headers";
      if (tab === 1) return "Query Params";
      if (tab === 2) return "Body";
    },
    hideParams() {
      if (this.$refs.dataKeyValueTemplate)
        this.$refs.dataKeyValueTemplate.classList.replace("block", "hidden");
    },
    setParam() {
      if (!this.request[this.destination]) this.request[this.destination] = {};
      if (this.paramType === "entity")
        this.request[this.destination][this.paramKey] = `@${this.paramEntity}`;
      if (this.paramType === "custom") {
        this.request[this.destination][this.paramKey] = this.paramCustom;
        if (this.paramCustom.startsWith("@"))
          this.entities.push(this.paramCustom);
      }
      if (this.paramType === "otherRequest")
        this.request[this.destination][
          this.paramKey
        ] = `requestName:${this.paramOtherRequest}`;
      this.componentKey += 1;
      this.resetParams();
      this.hideParams();
    },
    unsetParam(destination, index) {
      delete this.request[destination][index];
      this.componentKey += 1;
    },
    resetParams() {
      this.testResponseStatus = null;
      this.testResponseData = null;
      this.testResponseJPath = null;
      this.testResponseDataRaw = null;
      this.paramKey = "";
      this.paramEntity = "";
      this.paramCustom = "";
      this.paramOtherRequest = "";
      if (
        this.$refs.paramEntity &&
        !this.$refs.paramEntity.$el.classList.contains("hidden")
      )
        this.$refs.paramEntity.$el.classList =
          this.$refs.paramEntity.$el.classList + " hidden";
      if (
        this.$refs.paramCustom &&
        !this.$refs.paramCustom.$el.classList.contains("hidden")
      )
        this.$refs.paramCustom.$el.classList =
          this.$refs.paramCustom.$el.classList + " hidden";
      if (
        this.$refs.paramOtherRequest &&
        !this.$refs.paramOtherRequest.$el.classList.contains("hidden")
      )
        this.$refs.paramOtherRequest.$el.classList =
          this.$refs.paramOtherRequest.$el.classList + " hidden";
    },
    addParam(dest, type) {
      this.paramType = type;
      this.$refs.dataKeyValueTemplate.classList.replace("hidden", "block");
      this.resetParams();
      this.destination = dest;
      if (type === "custom")
        this.$refs.paramCustom.$el.classList.replace("hidden", "block");
      if (type === "entity")
        this.$refs.paramEntity.$el.classList.replace("hidden", "block");
      if (type === "otherRequest")
        this.$refs.paramOtherRequest.$el.classList.replace("hidden", "block");
    },
    addForceQuestionID() {
      this.forceQuestionJPathValue.push(this.forceQuestionJPathValueADD);
      this.forceQuestionID.push(this.forceQuestionIDADD);
      this.forceQuestionJPathValueADD = "";
      this.forceQuestionIDADD = { value: "", label: "" };
    },
    deleteForceQuestionID(index) {
      this.forceQuestionID.splice(index, 1);
      this.forceQuestionJPathValue.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.vs-popup--content {
  height: 100% !important;
}
.vs-list--avatar {
  width: 100% !important;
}
</style>

<template>
  <!-- Chat Messages -->
  <div class="flex-1 p-4 overflow-y-auto" ref="chatBox">
    <div
      v-for="(message, index) in messages"
      :key="index"
      class="mb-4 flex"
      :class="message.type === 'outgoing' ? 'justify-end' : 'justify-start'"
    >
      <div
        class="max-w-xs px-4 py-2 rounded-lg"
        :class="
          message.type === 'outgoing'
            ? 'bg-primary-light text-black'
            : 'bg-grey-light text-grey-dark'
        "
      >
        {{ message.text }}
        <span
          class="block text-xs mt-1"
          :class="message.type === 'outgoing' ? 'text-black' : 'text-black'"
        >
          {{ message.time }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatBox",
  props: {
    initialMessages: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      messages: [...this.initialMessages],
      newMessage: "",
    };
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim()) {
        const currentTime = new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });

        const newMessage = {
          text: this.newMessage,
          type: "outgoing",
          time: currentTime,
        };

        this.messages.push(newMessage);
        this.newMessage = "";

        // Emit the new message event
        this.$emit("send-message", newMessage);

        // Scroll to the bottom of the chat box
        this.$nextTick(() => {
          const chatBox = this.$refs.chatBox;
          chatBox.scrollTop = chatBox.scrollHeight;
        });
      }
    },
  },
};
</script>

<style scoped>
/* Scoped styles, if necessary */
</style>
